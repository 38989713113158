import { InitAppForPageFn } from '@wix/yoshi-flow-editor';

import { initializeMonitoring, toMonitored } from './utils/monitoring';
import { initAppDataStore } from './services/app-data-store';
import {
  createPublicAPI as currentMACreatePublicAPI,
  initApplication,
} from './current-ma';
import {
  createPublicAPI as maV2CreatePublicAPI,
  getGlobalControllerConfig,
} from './ma-v2';

const getViewerPlatformExports = () => {
  const controllerConfig = getGlobalControllerConfig();
  const isMembersAreaV2Enabled = controllerConfig?.config.isMembersAreaV2;

  // TODO understand why controllerConfig becomes null
  // looks like controller is not called later on during installation
  // my guess it happens because installation runs twice

  return isMembersAreaV2Enabled
    ? maV2CreatePublicAPI()
    : currentMACreatePublicAPI();
};

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  platformServices,
  flowApi,
) => {
  initializeMonitoring(initParams, platformServices, flowApi.experiments);

  const { httpClient } = flowApi;
  const appDataStore = initAppDataStore(initParams, wixCodeApi, flowApi);
  const initApplicationPromise = toMonitored('initAppForPage', () =>
    initApplication(
      initParams as any,
      platformApis as any,
      wixCodeApi,
      httpClient,
      flowApi.experiments,
    ),
  )();

  return {
    initApplication: async () => {
      await initApplicationPromise;
      return appDataStore.getAppData();
    },
  };
};

export const exports = () => {
  // TODO investigate if we can take controller from exports props (pageReady)
  // this api is not documented afaik
  return getViewerPlatformExports();
};
